interface InfoToasterProps {
  message: string
  index: number
}

const InfoToaster = ({ message, index }: InfoToasterProps) => {
  return (
    <div
      className="fixed bottom-0 right-0 left-0 flex items-center bg-skyBlue px-2 min-h-10"
      style={{ bottom: `${index * 2.5}rem`, zIndex: 9999 }}
    >
      <div className="text-skyBlue rounded-full bg-white mr-3">
        <svg
          width="1.8em"
          height="1.8em"
          viewBox="0 0 16 16"
          className="bi bi-info"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588z" />
          <circle cx="8" cy="4.5" r="1" />
        </svg>
      </div>
      <div className="text-white max-w-xs ">{message}</div>
    </div>
  )
}

export default InfoToaster
