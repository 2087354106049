interface SuccessToasterProps {
  message: string
  index: number
}

const SuccessToaster = ({ message, index }: SuccessToasterProps) => {
  return (
    <div
      className="fixed bottom-0 right-0 left-0 flex items-center bg-kiwi px-2 min-h-10"
      style={{ bottom: `${index * 2.5}rem`, zIndex: 9999 }}
    >
      <div className="text-kiwi rounded-full bg-white mr-3">
        <svg
          width="1.8em"
          height="1.8em"
          viewBox="0 0 16 16"
          className="bi bi-check"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"
          />
        </svg>
      </div>
      <div className="text-white max-w-xs ">{message}</div>
    </div>
  )
}

export default SuccessToaster
