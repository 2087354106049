import getConfig from 'next/config'

import Icon from '../Icon'
import ImageWithFallback from '@/components/Image/ImageWithFallback'

import { IndexWidget } from '../IndexWidget'
import { Link } from '../Link/Link'
import { useRouter } from 'next/router'

const Footer = () => {
  const config = getConfig().publicRuntimeConfig
  const router = useRouter()
  const lang = router.query.lang

  return (
    <footer className="mt-12">
      {lang === 'eng' ? null : <IndexWidget />}

      <div className="container-padded flex py-5 lg:py-12">
        <ul className="flex w-full items-center flex-col xl:flex-row gap-4 xl:gap-2">
          <li>
            <Link href="/">
              <Icon
                name="mpe-logo-color"
                className="w-full md:w-40 mb-4 xl:mb-0 xl:mr-4"
                disableHover
              />
            </Link>
          </li>
          <li className="relative w-36 md:w-30 h-24 ">
            <Link href="https://kormany.hu" target="_blank" className="w-full">
              <ImageWithFallback
                src="/photos/images/emmi_white.png"
                fallbackSrc="/images/emmi_white.png"
                className="object-cover object-center"
                layout="fill"
              />
            </Link>
          </li>
          <li className="relative w-full md:w-40 h-12 ">
            <Link href="https://petofiugynokseg.hu" target="_blank">
              <ImageWithFallback
                src="/photos/images/pku-logo-2024.png"
                fallbackSrc="/images/pku-logo-2024.png"
                className="object-contain object-center"
                layout="fill"
              />
            </Link>
          </li>

          <li className="relative w-full md:w-20 h-12">
            <Link href="https://nka.hu" target="_blank">
              <ImageWithFallback
                src="/images/NKA2-01.png"
                fallbackSrc="/images/NKA2-01.png"
                className="object-contain object-center"
                layout="fill"
              />
            </Link>
          </li>
          <li className="relative w-full md:w-20 h-12">
            <Link href="https://a38.hu" target="_blank">
              <ImageWithFallback
                src="/images/A38_logo_white.png"
                fallbackSrc="/images/A38_logo_white.png"
                className="object-contain object-center"
                layout="fill"
              />
            </Link>
          </li>
          {/* <li className="relative w-24 h-10">
            <Link href="http://nevter.hu" target="_blank">
              <Image
                src="/images/nemzeti_nevter.png"
                className="object-contain object-center"
                layout="fill"
              />
            </Link>
          </li>
          <li className="relative w-40 h-10 mx-4">
            <Link href="https://csehtamasarchivum.hu/" target="_blank">
              <Image
                src="/images/csehtamas.png"
                className="object-contain object-center"
                layout="fill"
              />
            </Link>
          </li>
          <li className="relative w-40 h-10">
            <Link href="https://www.oszk.hu/" target="_blank">
              <Image
                src="/images/OSZK_logo.png"
                className="object-contain object-center"
                layout="fill"
              />
            </Link>
          </li> */}
          <li className="xl:ml-auto flex flex-row gap-2">
            <Link href={`/hivatkozasok-gyujtemenye`} className="underline text-sm">
              {lang === 'eng' ? 'References' : 'Hivatkozások gyűjteménye'}
            </Link>
            <Link
              href={`${config.privacyPolicyPdf}`}
              locale={false}
              className="underline text-sm cursor-pointer"
              target="_blank"
            >
              {lang === 'eng' ? 'Privacy statement' : 'Adatkezelési nyilatkozat'}
            </Link>
            {/* <Link
              className="underline text-sm cursor-pointer"
              href={config.privacyPolicyPdf}
              target="_blank"
            >
              Adatkezelési nyilatkozat
            </Link> */}
            <Link href={`${config.termsOfUse}`} className="underline text-sm">
              {lang === 'eng' ? 'Terms of Use' : 'Felhasználási feltételek'}
            </Link>
            <Link href={`/impresszum`} className="underline text-sm">
              {lang === 'eng' ? 'Impressum' : 'Impresszum'}
            </Link>
          </li>
        </ul>
      </div>
    </footer>
  )
}

export default Footer
