import { useEffect } from 'react'
import Head from 'next/head'
import getConfig from 'next/config'

const CustomHead = () => {
  useEffect(() => {
    try {
      if (process.env.NODE_ENV === 'production') {
        /*;(window as any).FB.getLoginStatus(function (response: any) {
          // TODO: login automatically if fb
        })*/
      }
    } catch (error) {
      console.error(error)
    }
  }, [])

  const runtimeConfig = getConfig().publicRuntimeConfig ?? {}

  return (
    <Head>
      {runtimeConfig?.fbAppId && (
        <>
          <script
            dangerouslySetInnerHTML={{
              __html: `
            window.fbAsyncInit = function() {
            FB.init({
              appId: '${runtimeConfig.fbAppId}',
              autoLogAppEvents: true,
              xfbml: true,
              version: 'v14.0'
            });
          };`
            }}
          />

          <script
            async
            defer
            crossOrigin="anonymous"
            src="https://connect.facebook.net/en_US/sdk.js"
          />
        </>
      )}
    </Head>
  )
}

export default CustomHead
