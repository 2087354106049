import { hideThemes, navigationSelector, showThemes } from '@/store/slices/navigationSlice'
import { isThemePath } from '@/utils'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from '../Link/Link'

export function EngNavigation() {
  const dispatch = useDispatch()
  const { themesVisibility } = useSelector(navigationSelector)
  const { asPath } = useRouter()

  const engThemes: Array<{ link: string; title: string }> = [
    {
      link: '/korszak',
      title: 'Eras'
    },
    {
      link: '/eloado',
      title: 'Performers'
    },
    {
      link: '/szemely',
      title: 'Musicians'
    },
    {
      link: '/esemeny',
      title: 'Events'
    },
    {
      link: '/esemenysorozat',
      title: 'Event series'
    },
    {
      link: '/helyszin',
      title: 'Venues'
    },
    {
      link: '/kiadvany',
      title: 'Discography'
    },
    {
      link: '/kiadvanyelem',
      title: 'Publications'
    }
  ]

  const classes = {
    link: (active?: boolean) =>
      `py-0.5 ${
        active ? 'border-white' : ''
      } border-b border-transparent hover:border-white cursor-pointer transition-all`
  }

  const closeThemeNav = () => {
    if (!isThemePath(asPath)) {
      dispatch(hideThemes())
    }
  }

  useEffect(() => {
    if (isThemePath(asPath)) {
      dispatch(showThemes())
    } else {
      dispatch(hideThemes())
    }
  }, [asPath])

  return (
    <nav
      className={`transition-all navigation bg-gradient-to-b to-indigo from-skyBlue tracking-wide overflow-hidden hidden xl:block ${
        themesVisibility ? 'max-h-16' : 'max-h-0'
      }`}
      onMouseLeave={closeThemeNav}
    >
      <div className="container-padded flex flex-wrap xl:flex-nowrap xl:justify-between">
        {engThemes.map(({ link, title }) => (
          <Link href={`${link}/1`} key={link} className="pr-3 md:pr-6 py-4">
            <span className={classes.link(asPath.includes(`${link}/`))}>{title}</span>
          </Link>
        ))}
      </div>
    </nav>
  )
}
