import { useEffect } from 'react'

function IndexWidget() {
  useEffect(() => {
    setTimeout(() => {
      const src = `https://cdn.contrec.index.hu/icwsMin.js?d=${new Date().getTime()}`
      const newScript = document.createElement('script')
      newScript.setAttribute('async', 'true')
      newScript.setAttribute('src', src)
      document.body.appendChild(newScript)
    }, 50)
  }, [])

  return (
    <div className="container-padded flex py-5 lg:py-12">
      <div
        id="indaContrecWidget29"
        className="indaContrecWidget"
        data-href="29"
        data-param=""
      ></div>
    </div>
  )
}

export { IndexWidget }
