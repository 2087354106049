import { useSelector } from 'react-redux'

import FailToaster from './Fail'
import InfoToaster from './Info'
import SuccessToaster from './Success'

import { toasterSelector, Toaster as ToasterType, success, fail } from '@/store/slices/toasterSlice'

const Toaster = () => {
  const { content } = useSelector(toasterSelector)
  return (
    <>
      {content.length > 0
        ? content.map((c: ToasterType, i: number) => {
            if (c.type === success) {
              return <SuccessToaster message={c.message} key={i} index={i} />
            } else if (c.type === fail) {
              return <FailToaster message={c.message} key={i} index={i} />
            } else {
              return <InfoToaster message={c.message} key={i} index={i} />
            }
          })
        : null}
    </>
  )
}

export default Toaster
