import { MouseEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { modalSelector, hide } from '@/store/slices/modalSlice'

import modalStyles from './modalStyles.module.css'

const Modal = () => {
  const dispatch = useDispatch()
  const modal = useSelector(modalSelector)

  return (
    <div
      className={`${modalStyles.overlay} ${
        modal.open && modalStyles.open
      } absolute min-w-full min-h-screen bg-black bg-opacity-60 z-50 flex justify-center items-center`}
      onClick={() => dispatch(hide())}
      style={{ top: `${modal.top}px` }}
    >
      <div
        className="flex w-full sm:w-10/12 xl:w-4/12"
        onClick={(e: MouseEvent) => {
          e.stopPropagation()
        }}
      >
        {[...modal.content].pop()}
      </div>
    </div>
  )
}

export default Modal
