import { ReactNode, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import Footer from '../Footer'
import Head from '../Head'
import Icon from '../Icon'
import Modal from '../Modal'
import Navigation from '../Navigation'
import Toaster from '../Toaster'
import TopNavigation from '../TopNavigation/TopNavigation'

import { IBreadcrumb } from '@/hooks/useBreadcrumbs'
import { modalSelector } from '@/store/slices/modalSlice'
import { navigationSelector } from '@/store/slices/navigationSlice'
import { Link } from '../Link/Link'
import { useRouter } from 'next/router'
import { EngTopNavigation } from '../EngTopNavigation'
import { EngNavigation } from '../EngNavigation'

interface ILayout {
  children: ReactNode
  breadcrumbs?: IBreadcrumb[]
  hideNav?: boolean
  hideHeader?: boolean
}

export default function Layout({ children, breadcrumbs = [], hideHeader }: ILayout) {
  const router = useRouter()
  const lang = router.query.lang
  const { open } = useSelector(modalSelector)
  const { overlay } = useSelector(navigationSelector)
  const wrapperRef = useRef<HTMLDivElement>(null)
  const [hide, setHide] = useState(hideHeader)
  const classes = {
    hideHeader: hide ? '-top-28 fixed' : 'top-0 fixed'
  }

  useEffect(() => {
    document.body.style.overflow = open || overlay ? 'hidden' : 'auto'
  }, [open, overlay])

  useEffect(() => {
    window.addEventListener('scroll', (e) => handleNavigationHiding(e))
    return () => {
      window.removeEventListener('scroll', handleNavigationHiding)
    }
  }, [])

  const handleNavigationHiding = (e: any) => {
    setHide(hideHeader && e.currentTarget && e.currentTarget.scrollY < 250)
  }

  return (
    <div
      ref={wrapperRef}
      className="flex flex-col min-h-screen text-zinc-800 leading-relaxed max-w-full overflow-x-hidden"
    >
      <Head />

      <header
        style={{ transition: 'top 1s ease .5s' }}
        className={`flex flex-col bg-black w-full z-50 ${classes.hideHeader}`}
      >
        {lang === 'eng' ? (
          <>
            <EngTopNavigation />
            <EngNavigation />
          </>
        ) : (
          <>
            <TopNavigation />
            <Navigation />
          </>
        )}
      </header>

      <main className="flex flex-1 flex-col">
        {children}
        {breadcrumbs?.length ? (
          <section className="container-padded my-16">
            <h2>{lang === 'eng' ? 'You came from:' : 'Innen érkeztél:'}</h2>

            <ul className="flex my-8">
              {breadcrumbs.map((crumb, i) => (
                <li className="flex" key={i}>
                  <Link
                    href={crumb.url}
                    className="border-b border-transparent hover:border-white transition-all"
                  >
                    {crumb.title}
                  </Link>

                  {i === breadcrumbs.length - 1 ? null : (
                    <Icon name="arrow-right" className="w-8 mx-2" />
                  )}
                </li>
              ))}
            </ul>
          </section>
        ) : null}
      </main>
      <Modal />
      <Toaster />
      <Footer />
    </div>
  )
}
