import React, { useState } from 'react'
import Image, { ImageProps } from 'next/image'

type ImageWithFallbackProps = ImageProps & {
  fallbackSrc?: string
}

const ImageWithFallback = (props: ImageWithFallbackProps) => {
  const { src, fallbackSrc, ...rest } = props
  const [imgSrc, setImgSrc] = useState(src)
  const [unoptimized, setUnoptimized] = useState(false)

  return (
    <Image
      {...rest}
      src={imgSrc}
      unoptimized={unoptimized}
      onError={() => {
        if (fallbackSrc) {
          setImgSrc(fallbackSrc)
          setUnoptimized(true)
        }
      }}
    />
  )
}

export default ImageWithFallback
