interface FailToasterProps {
  message: string
  index: number
}

const FailToaster = ({ message, index }: FailToasterProps) => {
  return (
    <div
      className="fixed bottom-0 right-0 left-0 flex items-center bg-red px-2 min-h-10"
      style={{ bottom: `${index * 2.5}rem`, zIndex: 9999 }}
    >
      <div className="text-red rounded-full bg-white mr-3">
        <svg
          width="1.8em"
          height="1.8em"
          viewBox="0 0 16 16"
          className="bi bi-x"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M11.854 4.146a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708-.708l7-7a.5.5 0 0 1 .708 0z"
          />
          <path
            fillRule="evenodd"
            d="M4.146 4.146a.5.5 0 0 0 0 .708l7 7a.5.5 0 0 0 .708-.708l-7-7a.5.5 0 0 0-.708 0z"
          />
        </svg>
      </div>
      <div className="text-white max-w-xs ">{message}</div>
    </div>
  )
}

export default FailToaster
